import styles from "./Landing.module.css";
import blob1 from "./blob1.png";
import blob2 from "./blob2.png";
import blob3 from "./blob3.png";
import logo from "./logo_small.png";
import files from "./files.png";
import circle from "./logoCircle.png";
import { TbWorld } from "react-icons/tb";
import { FaLinkedin } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { MdNavigateNext } from "react-icons/md";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { useEffect, useState, useRef } from "react";
import Footer from "../footer/footer";
function Landing() {
	const documents = [
		{ name: "OECD Test Guidance" },
		{ name: "ISO Standard" },
		{ name: "ECVAM repositories" },
		{ name: "OECD Working Plan" },
		{ name: "Nano-relevant AOP" },
		{ name: "Scientific publications" },
		{ name: "EU project deliverables and SOPs" },
	];
	const [currSlide, setCurrSlide] = useState(0);

	// const interval = setInterval(() => {
	// 	next();
	// }, 1100000);
	function next(e) {
		setCurrSlide((prevSlide) => (prevSlide + 1) % 4);

		if (currSlide === 0) {
			scrollTo(section1);
		} else if (currSlide === 1) {
			scrollTo(section2);
		} else if (currSlide === 2) {
			scrollTo(section3);
		}
	}

	function prev() {
		if (currSlide == 0) {
			setCurrSlide(3);
		} else {
			setCurrSlide(currSlide - 1);
		}

		if (currSlide === 1) {
			scrollTo(section0);
		} else if (currSlide === 2) {
			scrollTo(section1);
		} else if (currSlide === 3) {
			scrollTo(section2);
		}
	}

	const section0 = useRef();
	const section1 = useRef();
	const section2 = useRef();
	const section3 = useRef();

	function scrollTo(section) {
		section.current.scrollIntoView({
			behavior: "smooth",
			block: "start",
			inline: "nearest",
		});
	}

	function wheelFunc(e) {}
	useEffect(() => {
		return () => {
			// clearInterval(interval);
		};
	}, [currSlide]);

	return (
		<div className={styles.block}>
			<img className={styles.firstBlob} src={blob2} />
			<img className={styles.secondBlob} src={blob3} />

			{
				<div className={styles.bars}>
					<div
						className={
							currSlide == 0
								? styles.activeBar
								: styles.inactiveBar
						}
						onClick={() => {
							setCurrSlide(0);
							scrollTo(section0);
						}}
					></div>
					<div
						className={
							currSlide == 1
								? styles.activeBar
								: styles.inactiveBar
						}
						onClick={() => {
							setCurrSlide(1);
							scrollTo(section1);
						}}
					></div>
					<div
						className={
							currSlide == 2
								? styles.activeBar
								: styles.inactiveBar
						}
						onClick={() => {
							setCurrSlide(2);
							scrollTo(section2);
						}}
					></div>
					<div
						className={
							currSlide == 3
								? styles.activeBar
								: styles.inactiveBar
						}
						onClick={() => {
							setCurrSlide(3);
							scrollTo(section3);
						}}
					></div>
				</div>
			}
			<div className={styles.mainPart} id="mainPart" onWheel={wheelFunc}>
				<div
					className={`${styles.section} ${styles.first}`}
					ref={section0}
				>
					{/* <img className={styles.firstBlob} src={blob3}/> */}
					{/* <img className={styles.secondBlob} src={logo}/> */}

					<div className={styles.secondBody}>
						<MdKeyboardArrowDown
								className={`${styles.butArr} ${styles.noDisp}`}
								onClick={next}
							/>
						<span className={styles.firstSectionMainTxt}>
							All available{" "}
						</span>
						<span className={styles.firstSectionMainTxt}>
							non-animal methods for
						</span>
						<div className={styles.firstSectionMainTxt}>
							hazard characterization
						</div>
						<div className={styles.firstSectionMainTxt}>
							in one place
						</div>
						<div className={styles.firstSectionSubTxt}>
							Great tool for regulators, academics & industry.
						</div>
						<button
							className={`${styles.slideBut} ${styles.downBut}`}
						>
							<MdKeyboardArrowDown
								className={styles.butArr}
								onClick={next}
							/>
						</button>
					</div>
				</div>

				<div
					className={`${styles.section}  ${styles.second}`}
					ref={section1}
				>
				
					<div className={styles.secondBody}>
						<button className={styles.slideBut}>
							<MdKeyboardArrowUp
								className={styles.butArr}
								onClick={prev}
							/>
						</button>
						<div className={styles.fourthSectionTitle}>
							About Database
						</div>
						<div className={styles.colorLine}></div>
						<div className={styles.secondTextList}>
							<div className={styles.secondSectionGreyTxt}>
								Given the ethical, economic, and time-related
								challenges associated with animal testing, as
								well as emerging bans on such methods, it is
								paramount to develop and validate alternative
								methods, known as{" "}
								<span className={styles.secondSectionGreenTxt}>
									New Approach Methodologies (NAMs).
								</span>
							</div>

							<div className={styles.secondSectionGreyTxt}>
								NAMs.network is the first database of
								regulatory-relevant NAMs for assessing the
								safety of nano-materials and chemicals, from
								where you can extract state-of-the-art knowledge
								on different NAMs.
							</div>
							<div className={styles.secondSectionGreyTxt}>
								Welcome to a comprehensive, easily accessible
								repository collecting all of the NAMs under EU
								Regulations in one place.
							</div>
						</div>
					
						<button
							className={`${styles.slideBut} ${styles.downBut}`}
						>
							<MdKeyboardArrowDown
								className={styles.butArr}
								onClick={next}
							/>
						</button>
					</div>
				</div>

				<div
					className={`${styles.section} ${styles.third}`}
					ref={section2}
				>
					{/* <img className={styles.fifthBlob} src={blob3}/> */}

					<div className={styles.secondBody}>
						<button className={styles.slideBut}>
							<MdKeyboardArrowUp
								className={styles.butArr}
								onClick={prev}
							/>
						</button>
						<div className={styles.fourthSectionTitle}>
							Database content
						</div>
						<div className={`${styles.colorLine} `}></div>

						<div className={styles.secondTextList}>
							<div className={styles.thirdText}>
								NAMs that were recently proposed and can be
								promising from the regulatory point of view
							</div>

							<div
								className={`${styles.colorLine} ${styles.shortLine}`}
							></div>
							<div className={styles.thirdText}>
								NAMs that are currently under validation or
								adaptation for regulatory purposes
							</div>
							<div
								className={`${styles.colorLine} ${styles.shortLine}`}
							></div>
							<div className={styles.thirdText}>
								NAMs that have already gained regulatory
								acceptance and legal approval
							</div>
							
						</div>
						<button
							className={`${styles.slideBut} ${styles.downBut}`}
						>
							<MdKeyboardArrowDown
								className={styles.butArr}
								onClick={next}
							/>
						</button>
					</div>
				</div>

				<div
					className={`${styles.section} ${styles.fourth}`}
					ref={section3}
				>
					{/* <img className={styles.sixthBlob} src={blob1}/> */}
					<div className={styles.secondBody}>
						<button className={styles.slideBut}>
							<MdKeyboardArrowUp
								className={styles.butArr}
								onClick={prev}
							/>
						</button>
						<span className={styles.fourthSectionTitle}>
							Documents collected
						</span>
						<span className={styles.fourthSectionTitle}>
							in the database
						</span>
						<div className={`${styles.colorLine} `}></div>
						<div className={styles.fourthSectionItemList}>
							{documents.map((x,i) => (
								<div key={i} className={styles.fourthSectionTxt}>
									<FaCheck className={styles.check} />
									<div className={styles.docName}>
										{x.name}
									</div>
								</div>
							))}
						</div>
						{/* <div
							className={`${styles.colorLine}  ${styles.lastLine} `}
						></div> */}
						<div className={styles.endSpace}></div>
					</div>
				</div>
			</div>
			{currSlide == 3 && <Footer />}
		</div>
	);
}
export default Landing;
