import  Select  from "react-select";
import axios from "axios";
import {useState,useEffect} from "react"
import styles from "./selector.module.css"
import { components } from "react-select";
import { useSelector } from "react-redux";
import { authActions } from '../../../../store/authSlice'
import { editActions } from "../../../../store/editSlice";
import { useDispatch} from 'react-redux'
import {useParams} from "react-router-dom"
import { MultiSelect } from "primereact/multiselect";
function MultiSelector(props){
        
        const dispatch=useDispatch()
        const[data,setData] =useState()
        
        const link=props.link
        const [selectedCities, setSelectedCities] = useState();
        const [it, setIT]= useState(0)
        
        //const firstValue =props.value.map((x) => ({label:x.trim(),value:x.trim()}))
        
        //const [test_value,setTest]=useState([{ value: "John", label: "John" },{ value: 1, label: "John2" },{value: 'Regulation (EC) 1223/2009', label: 'Regulation (EC) 1223/2009'}])
        
        
        
       
       
       
        const authToken=useSelector((state)=>state.auth.access)
        async function dataFetch(link){
          
            const requestList=await axios.get(`${process.env.REACT_APP_DOMAIN}/api/${link}`, {
              headers:{
                  'Content-Type':'application/json',
                  'Authorization':"Bearer " + String(authToken)
              }})
        
            .then(function(response){
              
              
                setData(response.data.map((x)=>({value:x.name,label:x.name})))

            })
            .catch(function(error){
               
              if (error.toJSON().status==401){
                dispatch(authActions.setEmail(null))
            dispatch(authActions.setAccess(null))
            dispatch(authActions.setReload(null))
            dispatch(authActions.setLoged(false))
            localStorage.removeItem('authTokens')
            }
            })
            .finally(function(){
                
            })
            return
        }
        
        useEffect(()=>{
            dataFetch(link)
            
            
        },[])
    
    
        
    
        const handleChange = (selected) => {
          setIT(1)
          setSelectedCities(selected.value)
        

            
            
              dispatch(editActions.setRegulations(selected.value))
              
            
            
        }

       
        return(
            <div className={styles.bar}>
                
               {it==0 ? 
               <MultiSelect
                placeholder="Select"
                options={data}
                className={styles.mypanel2}
              onChange={handleChange}
              value={props.value.map((x)=>(x.value))}
               filter
               display="chip"
             
             />
             :
             <MultiSelect
             placeholder="Select"
             options={data}
             className={styles.mypanel2}
           onChange={handleChange}
           value={selectedCities}
            filter
            display="chip"
          
          />
               }
            
            </div>
            
        )
    
}

export default MultiSelector