import Selector from "./selector"
import styles from "./mainFormSelect.module.css"
import MultiSelector from "./multiSelectior"
function MainFormSelect(props){
   
    const lib=[
        {
            name:"Organ/system",
            link:"organ",
            value:props.organ
        },

        {
            name:"NAM Type",
            link:"namType",
            value:props.namType
        },

        {
            name:"Endpoint",
            link:"endpoint",
            value:props.endpoints
        },

        {
            name:"Document type",
            link:"type",
            value:props.type

        },
        {
            name:"Guidance/strategy",
            link:"test",
            value:props.testingMethod
        },
        {
            name:"Regulations",
            link:"regulations",
            value:props.regulations.split(';').map((x)=>({value:x.trim(),label:x.trim()}))
        }
       
        
        
        
        
        
       
        ]
    const lib2=[
        {
            name:"Stage of NAM development",
            link:"stage",
            value:props.stage
        },

        

        {
            name:"Applied to ENMs",
            link:"applied",
            value:props.nanoUsed
        },

        {
            name:"Nano Validated",
            link:"nam",
            value:props.nanoValidated
        },

        {
            name:"AOP",
            link:"aop",
            value:props.aop
        },
        {
            name:"Key Event",
            link:"key_event",
            value:props.keyEvent
        },
        
        {
            name:"MIE",
            link:"mie",
            value:props.mie
        }
        
    ]
    
    
    return (
        <div className={styles.row}>
            <div className={styles.column}>
                {lib.map((x,i)=>
                    <div key={i} className={styles.rowInRow2}>
                        {x.name=="Guidance/strategy" ? <div className={styles.guidance}>{x.name}:</div>:<div>{x.name}:</div>}

                        {x.link!="regulations" ?<Selector  link={x.link} value={x.value} name={x.name}/>:
                        <MultiSelector link={x.link} value={x.value} />}
                    </div>)}
            </div>

            <div className={styles.column}>
                {lib2.map((x,i)=>
                    <div key={i} className={styles.rowInRow}>
                    <div>{x.name}:</div>

                    {x.link!="regulations" ?<Selector  link={x.link} value={x.value} name={x.name}/>:
                    <MultiSelector link={x.link} value={x.value}/>}
                </div>)}
                
            </div>
                
        </div>
    )
}

export default MainFormSelect