import Tlo from "../../../landing/blob2.png";
import styles from "./ServiceProvider.module.css";
import Sidebar from "../../sidebar/sidebar";
import { useSelector } from "react-redux";
import { useEffect, useState,} from "react";
import axios from "axios";
import FiltersExpander from "./filter/filterExpander";
import Table from "./table/table";
import blob5 from "../../../subPanel/5blob.png";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoMdCloseCircle } from "react-icons/io";
import { useSearchParams } from 'react-router-dom';
function ServiceProvider() {

	const [searchParams] = useSearchParams();
	const authToken = useSelector((state) => state.auth.access);

	const stages = useSelector((state) => state.labsFilter.stages);
	const organs = useSelector((state) => state.labsFilter.organs);

	const endpoints = useSelector((state) => state.labsFilter.endpoints);
	const tests = useSelector((state) => state.labsFilter.tests);
	const regulations = useSelector((state) => state.labsFilter.regulations);
	const aops = useSelector((state) => state.labsFilter.aops);
	const used = useSelector((state) => state.labsFilter.used);
	const namType = useSelector((state) => state.labsFilter.namType);
	const name = useSelector((state) => state.labsFilter.name);
	

	const [selectedId, setSelectedId] = useState(searchParams.get('nams')!=null?searchParams.get('nams').split(',').map((x)=>Number(x)):[]);

	const [selectedNames, setSelectedNames] = useState([]);

	
	const [errorModal,setErrorModal]=useState('')
	const [successModal,setSuccessModal]=useState(false)

	async function addProvider(e) {
		e.preventDefault();
		const file=e.target.logoInput.files[0]
		const data = {
			name: e.target.name.value,
			location:e.target.location.value,
			description: e.target.description.value,
			url: e.target.url.value,
			country:e.target.country.value,
			city:e.target.city.value,
			street:e.target.street.value,
			scope:e.target.scope.value,
			nams: selectedId.toString(),
			file:file
		};
		const request = await axios
			.post(
				`${process.env.REACT_APP_DOMAIN}/api/service_provider`,
				data,
				{
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: "Bearer " + String(authToken),
					},
				}
			)
			.then(function (response) {
				setSuccessModal(true)
			})
			.catch(function (error) {
				
			})
			.finally(function () {});
	}

	function confirm(){
		window.location.reload()
	}

	useEffect(() => {}, []);
	return (
		<div>
			{successModal &&
				<div className={styles.modalBlure}>
					<div className={styles.subModalBox}>
						<div className={styles.modalText}>
							<IoIosCheckmarkCircle className={styles.checkIcon}/>
							<div>Provider has been successfully added</div>
						</div>
						<button className={styles.okBut} onClick={confirm}>Ok</button>
					</div>
				</div>
			}

			{errorModal!=''&&
				<div className={styles.modalBlure}>
					<div className={styles.subModalBox}>
						<div className={styles.modalText}>
							<IoMdCloseCircle className={styles.errorIcon}/>
							<div>{errorModal}</div>
						</div>
						<button className={styles.errorBut} onClick={(e)=>setErrorModal('')}>Ok</button>
					</div>
				</div>
			}
			<div className={styles.block}>
				<Sidebar />
				<div className={styles.menu}>
					<div className={styles.blobHolder}>
						<img src={blob5} className={styles.firstBlob}></img>
						<img src={blob5} className={styles.secondBlob}></img>
						<div className={styles.blobText}>
							<div className={styles.blobTitle}>
								Add a new NAMs Provider
							</div>
						</div>
					</div>
					<form onSubmit={addProvider} className={styles.form}>
						<div className={styles.menuBlock}>
							<div className={styles.title}>Company details:</div>
							<div className={styles.background}>
								<div className={styles.compColOne}>
									<div className={styles.mainRow}>
										<div className={styles.label}>
											Name:
										</div>
										<input
											className={styles.input}
											id="name"
											required
											maxLength={299}
											defaultValue={searchParams.get('name')?searchParams.get('name'):''}
										></input>
									</div>
									
									<div className={styles.mainRow}>
									
											<div className={styles.label}>
												Location:
											</div>
											<input
												className={`${styles.input} `}
												id="location"
												required
											></input>
								
									
									</div>
									<div className={`${styles.locLabel}`}>
										Adress:
									</div>
									<div className={styles.locationRow}>
										<div className={styles.location}>
											<div className={styles.label}>
												Country:
											</div>
											<input
												className={`${styles.input} ${styles.locInp}`}
												id="country"
												required
												defaultValue={searchParams.get('country')?searchParams.get('country'):''}
											></input>
										</div>
										<div className={styles.location}>
											<div className={styles.label}>
												City:
											</div>
											<input
												className={`${styles.input} ${styles.locInp}`}
												id="city"
												required
												defaultValue={searchParams.get('city')?searchParams.get('city'):''}
											></input>
										</div>
										<div className={styles.location}>
											<div className={styles.label}>
												Street:
											</div>
											<input
												className={`${styles.input} ${styles.locInp}`}
												id="street"
												required
												defaultValue={searchParams.get('street')?searchParams.get('street'):''}
											></input>
										</div>
									</div>
								
										<div className={`${styles.mainRow} ${styles.lastRow}`}>
											<div className={styles.label}>
												Reference URL:
											</div>
										
												<input
													type="text"
													id="url"
													className={styles.input}
													required
													defaultValue={searchParams.get('url')?searchParams.get('url'):''}
												></input>
										
										</div>
										<div className={`${styles.mainRow} ${styles.lastRow}`}>
											<div className={styles.label}>Company Logo:</div>
											{/* <img src={logoUrl} alt="logo" className={styles.logo}></img> */}
											<input type='file' id='logoInput' accept='image/png, image/jpeg' className={`${styles.logoInput}`}></input>
										</div>
							
										
								</div>
								<div className={styles.compColOne}>
								<div className={`${styles.mainRow} ${styles.descRow}`}>
									<div className={styles.label}>
										Description:
									</div>
									
										<textarea
											className={styles.desc}
											type="text"
											id="description"
											maxLength={499}
											required
											defaultValue={searchParams.get('description')?searchParams.get('description'):''}
										></textarea>
									
								</div>
								<div className={`${styles.mainRow} ${styles.descRow}`}>
									<div className={styles.label}>
										Scope of Services:
									</div>
									
										<textarea
											className={styles.scope}
											type="text"
											id="scope"
											required
											defaultValue={searchParams.get('scope')?searchParams.get('scope'):''}
										></textarea>
									
								</div>
								</div>
							</div>
							<div className={styles.selectNamsTitle}>
								<div className={styles.title}>Provider's service scope:</div>
								<div className={styles.subtitle}>Select NAM declared in Provider’s application</div>
							</div>
							<FiltersExpander
								stages={stages}
								endpoints={endpoints}
								tests={tests}
								regulations={regulations}
								aops={aops}
								used={used}
								namType={namType}
								authToken={authToken}
								organs={organs}
								name={name}
							/>
							<Table
								selectedId={selectedId}
								setSelectedId={setSelectedId}
								selectedNames={selectedNames}
								setSelectedNames={setSelectedNames}
							/>
							<button className={styles.sendBut} type='submit'>
								Add
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}
export default ServiceProvider;

