import { Link } from "react-router-dom";
import Map from "./map"
import Accordion from 'react-bootstrap/Accordion';
import { useEffect, useState } from "react";
import styles from './labMenu.module.css'
function LabMenu(props){
  
    const labs=[
        {   key:1,
            name:'The European Union Reference Laboratory for Alternatives to Animal Testing (EURL ECVAM)',
            location:'Via Enrico Fermi, 2749, 21027 Ispra VA',
            experties:'EURL ECVAM is considered a leader in the validation and promotion of non-animal alternative methods for safety testing. They have a long track record of method development and assessment.',
            www:'https://joint-research-centre.ec.europa.eu/laboratories-and-facilities/eurl-ecvams-vitro-laboratory-facility_en '

        },

        {key:2,
            name:'CTIBiotech',
            location:'Bâtiment A, LYON FR, 5 Av. Lionel Terray Bat. A16, 69330 Meyzieu',
            experties:'Wide range of in vitro toxicological studies, including cytotoxicity, genotoxicity, skin irritation/corrosion, and phototoxicity.',
            www:'https://joint-research-centre.ec.europa.eu/laboratories-and-facilities/eurl-ecvams-vitro-laboratory-facility_en '

        },

        {key:3,
            name:'Institute for In Vitro Sciences, Inc. (IIVS)',
            location:'Ispra, Italy',
            experties:'Non-profit research and testing laboratory known for developing and implementing in vitro technologies focused on irritation testing (eye and skin), as well as pyrogenicity.',
            www:'https://joint-research-centre.ec.europa.eu/laboratories-and-facilities/eurl-ecvams-vitro-laboratory-facility_en '

        },
        {key:4,
            name:'Charles River Laboratories',
            location:'9 All. Moulin Berger, 69130 Écully',
            experties:'Global contract research organization offering a broad suite of preclinical services, including in vitro toxicology assays across various safety aspects.',
            www:'https://joint-research-centre.ec.europa.eu/laboratories-and-facilities/eurl-ecvams-vitro-laboratory-facility_en '

        },
        {key:5,
            name:'Cyprotex',
            location:'24, Mereside, Alderley Park, Nether Alderley, Macclesfield SK10 4TG',
            experties:'Specialized in ADME (Absorption, Distribution, Metabolism, and Excretion) toxicology with an array of in vitro assays relevant to chemical toxicity.',
            www:'https://joint-research-centre.ec.europa.eu/laboratories-and-facilities/eurl-ecvams-vitro-laboratory-facility_en '

        },
    ]

    // const [activeLoc,setActiveLoc]=useState(props.providers.length>0?props.providers[0].location:'')

   
   

    return(
        <div className={styles.block}>
           {props.providers.length>0 && <div className={styles.title}>{props.title}</div>}
            <div className={styles.row}>
                <Accordion defaultActiveKey="0" style={{"width":"100%"}}>
                    {props.providers.map((x)=>
                        <Accordion.Item eventKey={x.key} className={styles.item} key={x.key} >
                            <Accordion.Header className={styles.but}>{x.name}</Accordion.Header>
                            <Accordion.Body >
                                <div className={styles.accordBody}>
                                        <div className={styles.provInfo}>
                                            <div className={styles.adressInfo}>
                                                <div>
                                                    <div className={styles.title}>Adress</div>
                                                    <div className={styles.adressList}>
                                                        <div className={styles.adressEl}>{x.country}</div>
                                                        <div className={styles.adressEl}>{x.city}</div>
                                                        <div className={styles.adressEl}>{x.street}</div>
                                                    </div>
                                                </div>
                                                <img src={x.logo} className={styles.logo} alt="logo"></img>
                                            </div>
                                            <div className={styles.desc}>
                                                {x.description}
                                            </div>
                                            <div className={styles.title} style={{'marginTop':'20px'}}>Website</div>
                                            <Link to={x.url} className={styles.link}>{x.url}</Link>
                                            <div className={styles.title} style={{'marginTop':'20px'}}>Scope of Services</div>
                                            <div className={styles.desc} style={{'marginTop':'5px'}}>{x.scope}</div>
                                        </div>
                                
                                        <Map location={x.location}></Map>
                                </div>
                            </Accordion.Body>
                      </Accordion.Item>
                    )}
                </Accordion>
              
            </div>
        </div>
    )
}
export default LabMenu