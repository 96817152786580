import { Loader } from "@googlemaps/js-api-loader"
import { useEffect } from "react";
import styles from "./map.module.css"
function Map (props){

   
 
    
    return(
        <div className={styles.block}>
            <iframe
                width="100%"
                height="100%"

                loading="eager"
                referrerpolicy="no-referrer-when-downgrade"
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyC8xkhn0mx1vTu6bXSYhTgq7Pgqd_CSPbA&q=${props.location}`}>
            </iframe>
        </div>
    )
}
export default Map